import React from 'react';

const GoogleMap = (props) => {
  return (
    <div>
        <iframe
            src={props.mapLink}
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    </div>
  );
};

export default GoogleMap;