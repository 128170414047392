import React, { useState } from 'react';
import { Link } from 'gatsby';
import './NewsCard.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import ImageTransform from '../common/ggfx-client/module/components/image-transform';
import noImage from "../../images/no-image.png";
import { isMobile, isDesktop } from 'react-device-detect';
import PlayVideo from "../PlayVideo/PlayVideo";

import {PageLinks} from '../../components/common/constant';

const NewsCard = (props) => {
	const newsCard = props.newsCard;
	const [isPlay, setPlay] = useState(false);

    const ImageRenderList = ({ newsCards }) => {
      let processedImages = JSON.stringify({})
      if (newsCard.newsImgTransforms?.Tile_Image_Transforms) {
        processedImages = newsCard.newsImgTransforms?.Tile_Image_Transforms
      }
      return (
        <ImageTransform
          imagesources={newsCards?.newsImg} renderer="pic-src"
          imagename={props?.imagename}
          attr={{ alt: newsCards.newsImgAlt ? newsCards.newsImgAlt : newsCards.newsTitle, className: "m-0" }}
          imagetransformresult={processedImages} id={newsCards?.id}
        />
      )
    }

	return (
		<React.Fragment>
            <ScrollAnimation animateIn="fadeInUp">
                <div className='news-card'>
                    <div className="news-img position-relative">
                        {(newsCard.newsImg != "" && newsCard.newsImg != undefined) && 
                            newsCard?.id === "635aa439ede4b6e29622183e" ? (
                                <a href="https://pub1.pskt.io/t/a6xs7z" target="_blank">
                                  <ImageRenderList newsCards={newsCard} />
                                </a>
                            ) : newsCard?.id !== "635aa439ede4b6e29622183e" && props.path ? (
                                <Link to={props.path + newsCard.newsUrl}>
                                  <ImageRenderList newsCards={newsCard} />
                                </Link>
                            ) : (
                                <ImageRenderList newsCards={newsCard} />
                            )
                        }
                        {newsCard.newsImg == undefined &&
                            <React.Fragment>
                                {
                                    props.path ?
                                    <Link to={props.path+newsCard.newsUrl}>
                                        <img src={noImage} alt={newsCard.newsTitle} className="m-0"/>
                                    </Link> :
                                    <img src={noImage} alt={newsCard.newsTitle} className="m-0"/>
                                }
                            </React.Fragment>
                        }
                        {(newsCard.newsVideo == true && newsCard?.videoID) &&
                            <button 
                                type="button" 
                                className="play-btn" 
                                onClick={()=> {
                                    setPlay(true);
                                }}
                            >
                                <i className="video-icon"></i>
                            </button>
                        }
                        {(newsCard?.videoID && isPlay) && <div className="full-video">
                            {isPlay && 
                                <React.Fragment>
                                    <div className="d-lg-none d-block">
                                        <PlayVideo
                                            isOpen={isPlay}
                                            videoId={newsCard?.videoID}
                                            stopPlay={setPlay}
                                            width={'100%'} height={isDesktop ? '600px' : '100%'}
                                            fullscreen={isDesktop ? false : true}
                                        />
                                    </div>
                                </React.Fragment>
                            }
                        </div>}
                    </div>
                    <div className="news-heading">
                    {newsCard.newsTag && <span className='tag-name'>{newsCard.newsTag}</span>}
                        {newsCard.newsUrl ? <h3>
                            <Link to={`${props.path+newsCard.newsUrl}`}>{newsCard.newsTitle}</Link>
                        </h3> : 
                        <h3>{newsCard.newsTitle}</h3>
                        }
                    </div>
                </div>
            </ScrollAnimation>
            {/* <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId={newsCard.videoID}/>  */}
		</React.Fragment>
	)
}

export default NewsCard;