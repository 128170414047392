import React from 'react';
import {Link} from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';
import './BookCard.scss';
import { GetURL } from '../utils';
import HTMLReactParser from 'html-react-parser';
import ReapitBook from '../ReapitBook';

const Bookcard = (props) => {
    return (
        <div className='book-card'>
            {props?.column ? 
                <ScrollAnimation animateIn="fadeInUp">
                    <div className="book-card-appointment flex-column">
                        <div className="appointment-content">
                            <h2>{props.bookcardTitle}</h2>
                            <p className='d-none d-md-block rich-text-cnt py-3'>{HTMLReactParser(props.bookcardContent)}</p>
                        </div>
                        <div className='text-center w-100'>
                            {props.bookcardLink ? 
                                <Link to={GetURL(props?.bookcardLink?._id)} className='btn mt-3 px-0'>{props.bookcardBtn}</Link> :
                                <ReapitBook className='btn cursor-pointer mt-3 mx-auto px-0 w-100' type="[&quot;sales&quot;,&quot;lettings&quot;]">
                                    {props.bookcardBtn}
                                </ReapitBook>
                            }
                        </div>
                    </div>
                </ScrollAnimation> : 
                <ScrollAnimation animateIn="fadeInUp">
                    <div className="book-card-appointment">
                        <div className="appointment-content">
                            <h2>{props.bookcardTitle}</h2>
                            <p className='d-none d-md-block'>{props.bookcardContent}</p>
                        </div>
                        {props.bookcardLink ? 
                            <Link to={GetURL(props?.bookcardLink?._id)} className='btn'>{props.bookcardBtn}</Link> :
                            props?.eventPopup ? 
                            <button className='btn cursor-pointer' onClick={props.openEventForm}>{props.bookcardBtn}</button> :
                            <ReapitBook className='btn cursor-pointer' type="[&quot;sales&quot;,&quot;lettings&quot;]">{props.bookcardBtn}</ReapitBook> 
                        }
                    </div>
                </ScrollAnimation>
            }
        </div>
    );
}

export default Bookcard;
