import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import PropertyCard from "../PropertyCard/PropertyCard";
import Slider from "react-slick";
import gql from "graphql-tag";
import classNames from "classnames";
import './PropertiesByArea.scss';

import { useQuery } from "@apollo/client";


const propertyListQuery = gql`
    query FeaturedProperties($Prop_Area: String!) {
        sales: properties(where: { display_address_contains: $Prop_Area, search_type: "sales", publish: true }, limit: 10) {
            _id
            id
            display_address
            images
            price
            price_qualifier
            search_type
            slug
            floorarea_max
            floorarea_min
            floorarea_type
            building
            bedroom
            bathroom
            address
            imagetransforms
            max_price
            status
            title
            virtual_tour
        }
        lettings: properties(where: { display_address_contains: $Prop_Area, search_type: "lettings", publish: true }, limit: 10) {
            _id
            id
            display_address
            images
            price
            price_qualifier
            search_type
            slug
            floorarea_max
            floorarea_min
            floorarea_type
            building
            bedroom
            bathroom
            address
            imagetransforms
            max_price
            status
            title
            virtual_tour
        }
    }
`


const PropertiesByArea = (props) => {
    const [type, setType] = React.useState("sales");
    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                 settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                 settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,                       
                    dots: false
                }
            },
        ]
    };

    const area = "kensington";

    const { data, loading, error } = useQuery(propertyListQuery, {
        variables: {
            Prop_Area: area
        }
    });
    
    return (
        <div className="similar-property">
            <Container>
                <Row>
                    <Col>
                        {/* <h3>similar properties in kensington</h3> */}
                        <Tab.Container id="property-tabs" defaultActiveKey="sales">
                            <Nav variant="pills" className="mb-3">
                                {data?.sales?.length > 0 && <Nav.Item className="ms-0">
                                    <Nav.Link as="h3" eventKey="sales" className={classNames("ps-0 pe-3", {"active": type === "sales"})} onClick={() => setType("sales")}>
                                        PROPERTIES FOR SALE IN {area?.toUpperCase()}
                                    </Nav.Link>
                                </Nav.Item>}
                                {data?.lettings?.length > 0 && <Nav.Item className="ms-0">
                                    <Nav.Link as="h3" eventKey="lettings" className={classNames("ps-0 pe-3", {"active": type === "lettings"})} onClick={() => setType("lettings")}>
                                        PROPERTIES TO RENT IN {area?.toUpperCase()}
                                    </Nav.Link>
                                </Nav.Item>}
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="sales">
                                    <Slider className="slick-arrow" {...settings}>
                                        {data?.sales?.map((property, i) => 
                                            <div key={property._id}>
                                                <PropertyCard
                                                    key={property._id}
                                                    propertyList={{
                                                        propertyImg: property.images.filter(c => c.url != null)[0]?.url,
                                                        propertyTitle: property.title,
                                                        propertyPrice: `£ ${property.price.toLocaleString()}`,
                                                        propertyInfo: property.display_address,
                                                        propertyRoom: property.bedroom,
                                                        propertyBathroom: property.bathroom,
                                                        propertyReception: property.bathroom,
                                                        callInfo:'00000000',
                                                        propertyLink: `/property-${property.search_type === "sales" ? "for-sale" : "to-rent"}/${property.slug}-${property.id}/`,
                                                        propertyStatus: property.search_type === "sales"
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Slider>
                                </Tab.Pane>
                                <Tab.Pane eventKey="lettings">
                                    <Slider className="slick-arrow" {...settings}>
                                        {data?.lettings?.map((property, i) => 
                                            <div key={property._id}>
                                                <PropertyCard
                                                    key={property._id}
                                                    propertyList={{
                                                        propertyImg: property.images.filter(c => c.url != null)[0]?.url,
                                                        propertyTitle: property.title,
                                                        propertyPrice: `£ ${property.price.toLocaleString()}`,
                                                        propertyInfo: property.display_address,
                                                        propertyRoom: property.bedroom,
                                                        propertyBathroom: property.bathroom,
                                                        propertyReception: property.reception,
                                                        callInfo:'00000000',
                                                        propertyLink: `/property-${property.search_type === "sales" ? "for-sale" : "to-rent"}/${property.slug}-${property.id}/`,
                                                        propertyStatus: property.search_type === "sales",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Slider>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </div>          
    );
}

export default PropertiesByArea;
