import React, { useState, useEffect } from 'react';
import { Link } from "gatsby";
import { Container, Row, Col, Breadcrumb,Button } from "react-bootstrap"
import PropertyCard from "../../components/PropertyCard/PropertyCard"
import Slider from "react-slick";
import './SimilarProperty.scss'
import axios from 'axios';

const SimilarProperty = (props) => {
    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                 settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
            },
            {
                breakpoint: 991,
                 settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: false
                       
                    }
            },
            {
                breakpoint: 1500,
                 settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: false
                    }
            },
            {
                breakpoint: 2000,
                 settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: false
                    }
            },
         
        ]
    };

    const [propItems, setPropItems] = useState([]);
    const { property } = props;

    useEffect(() => {
        let similar_property_id = property.id;
        let similar_url = 'similar';
        let url = process.env.GATSBY_STRAPI_SRC + `/stb-lists/item/${similar_url}?pid=` + similar_property_id.replace("/#","").split("?")[0];
        getItems(url);
    }, []);

    const getItems = async url => {
        try {
            const { data } = await axios.get(url, { headers: { Authorization: "Bearer " + process.env.GATSBY_STRAPI_AT }})
            if (data && data.length > 0) {
                setPropItems(data)
            }
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <React.Fragment>
            {propItems && propItems.length >0 && <div className="similar-property ">
                <Container>
                    <Row>
                        <Col>
                            <h3>similar properties in kensington</h3>
                            <Slider className="slick-arrow" {...settings} infinite={propItems.length > 2}>
                                {propItems?.slice(0, 4).map((property, index) => 
                                    <PropertyCard 
                                        key={property.id} 
                                        searchResults={false}
                                        propertyList={{
                                            propertyImg: property.images[0]?.url,
                                            propertyTitle: property.intro,
                                            propertyPrice: `£ ${property.price.toLocaleString()}`,
                                            propertyInfo: property.title,
                                            propertyRoom: property.bedroom,
                                            propertyBathroom: property.bathroom,
                                            propertyReception: property.bathroom,
                                            propertyDesc: property.description,
                                            offerTag: property.status?.toLowerCase() === "under offer",
                                            offerText: "Under Offer",
                                            callInfo:'00000000',
                                            propertyLink: `/property-${property.search_type === "sales" ? "for-sale" : "to-rent"}/${property.slug}-${property.id}/`,
                                            propertyStatus: property.search_type === "sales"
                                        }}
                                    />
                                )}
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
        </React.Fragment>
            
        
        
            
        
    );
}

export default SimilarProperty;
