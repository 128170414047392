import React, {useState,useEffect } from 'react';
import { Link } from "gatsby";
import { Col, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import { isIOS } from 'react-device-detect';
import '../BookAppointment/BookAppointment.scss';

const OfficeBookAppointment = (props) => {
    var data = props.Lets_Together_Content
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        if (isIOS) {
          setRenderComponent(true)
        }else {
        window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })}
    }, [])
    
    return (
         <div className='appointment-section'> 
            <Container>
                <Row>
                    <Col>
                        <ScrollAnimation animateIn="fadeInUp" >
                            <div className="appointment-content">
                                <span className='sub-title'>{data?.Sm_Title}</span>
                                <h2>{data?.Title}</h2>
                                <div className="btn-row">
                                    {renderComponent && ( <>
                                        <Link to="/contact/property-valuation/ " className="btn">{data?.Cta_1_Label}</Link>
                                        </>
                                    )}
                                </div>
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default OfficeBookAppointment;
