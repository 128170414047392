import { graphql } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";
import Bookcard from "../components/BookCard/BookCard";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import LocalArea from "../components/LocalArea/LocalArea";
import OfficeDetail from "../components/OfficeDetail/OfficeDetail";
import PropertiesByArea from "../components/PropertiesByArea/PropertiesByArea";
import Seo from "../components/seo";
import SimilarProperty from "../components/SimilarProperty/SimilarProperty";

const GardenSquareTemplate = (props) => {
    const GQLPage = props.data.glstrapi?.office;
    const module = props.data.glstrapi?.globalModule;
    const teams = props.data.glstrapi?.teams;

    return (
        <React.Fragment>
            <Seo
                title={GQLPage.Meta_Title} 
                description={GQLPage.Meta_Description}
                location={props.location}
            />
            <Header menuLinks={[]}/>
            <OfficeDetail
                data={GQLPage}
                globalContent={module}
                teams={GQLPage.Select_Teams}
            />
            {GQLPage.Area_Guide && <LocalArea
                data={GQLPage.Area_Guide}
            />}
            <PropertiesByArea/>
            <Container>
                <Bookcard
                    bookcardTitle={module?.Book_A_Appointment?.Book_Title}
                    bookcardContent={module?.Book_A_Appointment?.Description}
                    bookcardBtn={module?.Book_A_Appointment?.Cta_Label}
                    bookcardLink={module?.Book_A_Appointment?.Cta_Link}
                />
            </Container>
            <Footer
                popularSearch="Popular_Contact"  showBreadcrumb = {true}
            />
        </React.Fragment>
    )
}

export default GardenSquareTemplate;

export const pageQuery = graphql`
    query officeQuery($id: ID!) {
        glstrapi {
            office(id: $id, publicationState: LIVE) {
                _id
                Title
                Url
                Video_Url
                Tile_Image {
                    alternativeText
                    url
                }
                Sort
                Contact_Link {
                    _id
                }
                Select_Team_Member {
                    _id
                    Url
                    Tile_Image {
                        alternativeText
                        url
                    }
                    Name
                    Mobile
                    Email
                    Video_Url
                    Designation
                }
                Select_Teams {
                    _id
                    Url
                    Name
                    Video_Url
                    Mobile
                    Email
                    Designation
                    Tile_Image {
                        alternativeText
                        url
                    }
                }
                Area_Guide {
                    _id
                    Title
                    Sm_Title
                    Content
                    Cta_Label
                    Video_Url
                    About_Cta_Link {
                        URL
                        _id
                    }
                    Italic_Text
                    Tile_Image {
                        alternativeText
                        url
                    }
                }
                Phone
                Opening_Hours
                Meta_Title
                Meta_Description
                Longitude
                Latitude
                Email
                Map_Link
                Content
                Content_Heading
                Address
                Alias
                imagetransforms
            }
            teams {
                _id
                Url
                Name
                Video_Url
                Mobile
                Email
                Designation
                Tile_Image {
                    alternativeText
                    url
                }
            }
            globalModule {
                Book_A_Appointment {
                    _id
                    Book_Title
                    Description
                    Cta_Label
                    Cta_Link {
                        _id
                        URL
                    }
                }
                Lets_Work_Together {
                    _id
                    Title
                    Sm_Title
                    Cta_1_Label
                    Cta_2_Label
                    Cta_1_Link {
                        URL
                        _id
                    }
                    Cta_2_Link {
                        URL
                        _id
                    }
                }
            }
        }
    }
`
