import React, { useState } from 'react';
import { Link } from "gatsby";
import { isDesktop } from 'react-device-detect';
import './ContactCard.scss';
import PlayVideo from "../PlayVideo/PlayVideo";

const ContactCard =(props) => {
    const [isPlay, setPlay] = useState(false);

    return (
        <React.Fragment>
            <div className="team-block">
                <div className="team-pic">
                    <img src={props.blockImg} className="h-75 object-pt object-fc" alt="property-img" />
                    {props.blockVideo && <i onClick={()=> {setPlay(true)}} className="video-icon"></i>}
                </div>
                <div className="team-contact">
                    <h3>{props.blockTitle}</h3>
                    <p className="d-none d-md-block">{props.blockContent}</p>
                    {(props?.blockBtn && props?.blockLink != null) && <Link to={props.blockLink} className="team-link">
                        {props.blockBtn}
                    </Link>}
                </div>
            </div>
            {(props?.blockVideo && isPlay) && <div className="full-video">
                {isPlay && <div className="d-block">
                    <PlayVideo
                        isOpen={isPlay}
                        videoId={props?.blockVideo}
                        stopPlay={setPlay}
                        width={'100%'} height={isDesktop ? '600px' : '100%'}
                        fullscreen={isDesktop ? false : true}
                        event={"Contact"}
                    />
                </div>}
            </div>}
        </React.Fragment>
    );
}

export default ContactCard;
